import React from "react";
import PropTypes from "prop-types";
import AccordionItem from "./accordion-item";
import Separator from "./separator";
import styles from "./accordion-group.module.scss";

const AccordionGroup = props => {
  const { heading, accordionItems } = props;
  return (
    <section className={styles.accordionGroup}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {accordionItems.map((item, index) => (
        <React.Fragment key={item.heading}>
          {index !== 0 && <Separator isColor />}
          <AccordionItem
            className={styles.accordionItem}
            heading={item.heading}
            body={item.answer}
          />
        </React.Fragment>
      ))}
    </section>
  );
};

AccordionGroup.propTypes = {
  heading: PropTypes.string,
  accordionItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired
};

AccordionGroup.defaultProps = {
  heading: null
};

export default AccordionGroup;
