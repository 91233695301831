import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import AccordionGroup from "../components/accordion-group";
import CtaBlock from "../components/cta-block";

const DiabeticEducationConsultations = ({ data }) => {
  const {
    description,
    banner,
    education,
    ctaBlock
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
      />
      <AccordionGroup
        heading={education.heading}
        accordionItems={education.criteriaAccordion}
      />
      <CtaBlock
        heading={ctaBlock.heading}
        text={ctaBlock.text}
        ctaLink={ctaBlock.button.link}
        ctaName={ctaBlock.button.name}
      />
    </Layout>
  );
};

export default DiabeticEducationConsultations;

DiabeticEducationConsultations.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: { templateKey: { eq: "diabetic-education-consultations" } }
    ) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
        }
        education {
          heading
          criteriaAccordion {
            heading
            answer
          }
        }
        ctaBlock {
          heading
          text
          button {
            name
            link
          }
        }
      }
    }
  }
`;
