import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import styles from "./accordion-item.module.scss";

const AccordionItem = props => {
  const { className, heading, body } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${className} ${isOpen && styles.open}`}>
      <button className={styles.button} onClick={toggleAccordion} type="button">
        <h4 className={styles.accordionHeading}>{heading}</h4>
        <div className={styles.indicator}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#222222"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        </div>
      </button>
      <div className={styles.accordionBody}>
        <ReactMarkdown source={body} />
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

AccordionItem.defaultProps = {
  className: null
};

export default AccordionItem;
